<div style="padding: 10px 10px 10px 0px" class="container">
  <div [hidden]="submitted">
    <div>
      <h3 style="background-color: #005ea2; color: white;height: 50px;padding: 8px;">Invite New Company User or Admin
      </h3>
    </div>
    <h3>Want to invite a new Company User or Admin?</h3>
    <p>
      You can invite a user under any or multiple organization for which you are authorized to manage the users. You can
      decide whether the user can access only the requests for confidentiality that they they submitted or all requests
      submitted by others under an organization. You can also decide whether the user is a regular user -- Submitting
      Entity User (SE User) or a Submitting Entity Administrator (SE Admin) like yourself, able to manage other users
      and access all requests for confidentiality submitted by anyone for their organization.
    </p>
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
      <div>
        <input type="checkbox" class="form-control" id="is_third_party_user" [(ngModel)]="user.is_third_party_user"
          name="is_third_party_user" #is_third_party_user="ngModel" style="width:20px;">
        <div style="margin-top:-27px; margin-left:25px"> <strong> This will be a third party User for our company.
          </strong></div>
      </div>
      <div>
        <div style="width:300px; padding:10px; margin-left:-10px;" class="inline-block-child">
            <label for="email">Email<sup style="color:red"> *</sup></label>
          <input type="text" class="form-control" id="email"
                 required pattern="^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$"
                 [(ngModel)]="user.email" name="email"
                 #email="ngModel">
          <div *ngIf="!email.valid && email.touched"
               class="alert alert-danger">
           Valid Email is required
          </div>
        </div>
          <div style="width:300px;" class="inline-block-child">
            <label for="reenter_email">Re-enter Email<sup style="color:red"> *</sup></label>
          <input type="text" class="form-control" id="reenter_email"
                 required pattern="^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$"
                 [(ngModel)]="user.reenter_email" name="reenter_email"
                 #reenter_email="ngModel">
          <div *ngIf="user.email != user.reenter_email && reenter_email.touched && email.touched"
               class="alert alert-danger">
            Email needs to match
          </div>
          <div *ngIf="!reenter_email.valid && reenter_email.touched"
               class="alert alert-danger">
          Valid Email is required
          </div>
        </div>
        <div class="usa-alert__body" style="margin-top: 25px;">
          <p class="usa-alert__text">
            Please click on the fields in the table below to display selectable options 
          </p>
        </div>
        </div>
        <div style="padding-top:10px; height: 100%; width:100%;"
          *ngIf="user.ManufacturerAssignments">
          <ag-grid-angular class="ag-theme-balham fill-height"
            [rowData]="user.ManufacturerAssignments"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            >
          </ag-grid-angular>
        </div>
        
        <div style="padding-top:10px;">
          <button type="submit" class="usa-button usa-button--primary"
            [disabled]="!userForm.form.valid || user.email !== user.reenter_email">Send</button>
          <!-- <button type="submit" class="btn btn-success" [disabled]="!userForm.form.valid || getActiveSubmissionEntity(user.ManufacturerAssignments || []).length === 0">Submit</button> -->
          <!-- <button type="button" class="btn btn-default" (click)="newUser(); userForm.reset()">Reset</button> -->
          <button type="button" class="usa-button usa-button--primary" (click)="newUser(); userForm.reset()">Reset</button>
        </div>
      </form>
    </div>

    <div [hidden]="!submitted">
      <h2>Invitation is sent to user!</h2>
      <div class="row">
        <div class="col-xs-3">Email</div>
        <div class="col-xs-9">{{ user.email }}</div>
      </div>

      <div *ngIf="user" style="width: 100%; ">
        <div style="height: 100%"
          *ngIf="getActiveSubmissionEntity(user.ManufacturerAssignments || []).length > 0">
          <ag-grid-angular class="ag-theme-balham fill-height"
            [rowData]="getActiveSubmissionEntity(user.ManufacturerAssignments || [])"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            >
          </ag-grid-angular>
        </div>
        <!-- <div *ngIf="getActiveSubmissionEntity(user.ManufacturerAssignments || []).length === 0" class="row">
            <span><br><br>
              <b>Submitting Entities are not selected for this user!. User will not be visible.</b>
            </span>
        </div> -->
      </div>
    
    </div>
  </div>