import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, ManufacturerAssignment, ddtype } from './user';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service.service';
import { RadioControlValueAccessor } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private application_id = environment.application_id;
  __setContentType(headers: HttpHeaders) {
    headers = headers.append("Content-Type", "application/json");
    headers = headers.append("Accept", "application/json");
    // headers = headers.append("Authorization", "ServiceToken 471558f9-5df8-45ba-9110-bb9d4e62d99e");
    return headers;
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public authSvc: AuthService,

  ) {

  }


  getSubmissionEntityTypes(): ddtype[] {
    return [
      { id: 2, text: 'Submitting Entity User' },
      { id: 1, text: 'Submitting Entity Admin' },
    ];
  };
  getSubmissionEntities(): Observable<ManufacturerAssignment[]> {
    let requestHeaders = new HttpHeaders();
    requestHeaders = this.__setContentType(requestHeaders);
    const url = environment.apiUrl + "submissionentities?"
      // + "email=" + this.email + "&"
      + "application_id=" + this.application_id;
    const submissionEntities = this.http
      .get<any>(url, {
        headers: requestHeaders,
      })
      .pipe(
        catchError(this.handleError<User[]>('getSubmissionEntities', []))
      );
    this.messageService.add('User Management: getSubmissionEntities fetched');
    return submissionEntities;
  }

  getUsers(): Observable<User[]> {
    let requestHeaders = new HttpHeaders();
    requestHeaders = this.__setContentType(requestHeaders);
    const url = environment.apiUrl + "mfrusers?"
      // + "email=" + this.email + "&"
      + "application_id=" + this.application_id;
    const users = this.http
      .get<any>(url, {
        headers: requestHeaders,
      })
      .pipe(
        catchError(this.handleError<User[]>('getUsers', []))
      );

    this.messageService.add('User Management: fetched users');
    return users;
  }
  getUser(id: number): Observable<User> {
    let requestHeaders = new HttpHeaders();
    requestHeaders = this.__setContentType(requestHeaders);
    const url = environment.apiUrl + "mfruser" + "?user_id=" + id + "&" + "application_id=" + this.application_id;
    //+ '&logged_in_email=' + this.email;
    return this.http.get<User>(url, {
      headers: requestHeaders,
    }).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  updateUser(user: any): Observable<any> {
    console.log(user);
    let requestHeaders = new HttpHeaders();
    requestHeaders = this.__setContentType(requestHeaders);

    let url = environment.apiUrl + "managesubmissionentity" + "?" + "application_id=" + this.application_id;
    //url = url + "&user_id=" + this.loggedInuserId;
    const rtnval = this.http.post(url, user, {
      headers: requestHeaders,
    }).pipe(
      tap(_ => this.log(`updated user for =${user.email}`)),
      catchError(this.handleError<any>('Update User'))
    );
    console.log("http post returned", rtnval);
    return rtnval;
  }
  logAccess(email: string): Observable<any> {
    let requestHeaders = new HttpHeaders();
    requestHeaders = this.__setContentType(requestHeaders);
    const url = environment.apiUrl + `logaccess/${email}`;
    return this.http.post(url, null, {
      headers: requestHeaders,
    }).pipe(
      tap(_ => this.log(`log access for =${email}`)),
      catchError(this.handleError<any>('Log Access'))
    );
  }
  deleteUser(id: number): void {
    console.log(id);
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`${message}`);
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
