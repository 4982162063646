export type ManufacturerAssignment = {
  manufacturer_id: number;
  manufacturer_name: string;
  email_preference_id?: number;
  email_preference?: string;
  manufacturer_user_type_id?: number | 2;
  manufacturer_user_type_name?: string;
  is_account_documents_visible?: boolean | false;
  is_active?: boolean | false;
  contact_type?: string; 
}
export type role = {
  role_id: number,
  role_name: string
}
type EmailPreference = {
  statusid: number,
  status: string
}
export type ddtype = {
  id: number,
  text: string
};

export class submissionEntity {
  constructor(
    public manufacturer_id: number,
    public manufacturer_name: string,
  ) {}
}

export class User {
  manufacturers(manufacturers: any, arg1: string): any[] {
    throw new Error('Method not implemented.');
  }
  program_offices(program_offices: any, arg1: string): any[] {
    throw new Error('Method not implemented.');
  }
  constructor(
    public user_id?: number,
    public first_name?: string,
    public last_name?: string,
    public email?: string,
    public reenter_email?: string,
    public work_number?: string,
    public job_title?: string,
    public is_active?: boolean,
    public is_third_party_user?: boolean,
    public email_preferences?: EmailPreference[],
    public is_account_documents_visible?: boolean,
    public ManufacturerAssignments?: ManufacturerAssignment[],
    public roles?: role[],
    public contact_type?: string
  ) {}

  }

