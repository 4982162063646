import { Component } from '@angular/core';
import { ManufacturerAssignment, User } from '../user';
import { UserService } from '../user.service';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { SubheaderService } from '../subheader.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  gridOptions: GridOptions | undefined;

  user = new User();
  submitted = false;
  constructor(
    private userService: UserService,
    private subheader: SubheaderService
  ) { }
  onSubmit() { this.submitted = true; this.save(); }
  ngOnInit(): void {
    this.setGridOptions();
    this.newUser();
    this.subheader.setRoutingButtons();
  }
  newUser() {
    this.user = new User();
    this.user.is_active = true;
    this.user.is_third_party_user=false;
    this.userService.getSubmissionEntities()
      .subscribe(submissionEntities => this.user.ManufacturerAssignments = submissionEntities);
  }
  save(): void {
    if (this.user) {
      const sanitizedUser = {
        ...this.user,
        ManufacturerAssignments: this.user?.ManufacturerAssignments?.map((assignment: any) => {
          const { application, ...filteredAssignment } = assignment;
          return {
            ...filteredAssignment,
            is_active:true
          };
        }),
      };
      this.userService.updateUser(sanitizedUser).subscribe();
    }
  }
  public getActiveSubmissionEntity(data: ManufacturerAssignment[]): ManufacturerAssignment[] {
    if (data) {
      return data.filter(m => m.is_active);
    } else {
      return [];
    }

  }
  public gridReady = (event: GridReadyEvent): void => {
    event.api.sizeColumnsToFit();
    event.api.setColumnDefs(this.columnDefs);
  };
  setGridOptions(): void {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        headerClass: function(params) {
          return 'header_one';
        }
      },
      rowHeight: 31,
      columnDefs: this.columnDefs,
      onGridReady: (event) => this.gridReady(event),
      domLayout: 'autoHeight',
      rowSelection: 'single',
      suppressScrollOnNewData: true,
      animateRows: true,
      enableCellChangeFlash: true,
      colResizeDefault: 'shift',
      suppressClickEdit: false,
      suppressMenuHide: false,
      singleClickEdit: true
    };
  }
  columnDefs: ColDef[] = [
    {
      field: 'manufacturer_name',
      headerName: "Company",
      cellClass: 'table-cell',
      width: 234,

    },
    {
      field: 'contact_type',
      headerName: "Contact Type",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ["Select Contact Type", "Primary", "Secondary"],
      },
      valueFormatter: (params) => {
        return params.value || "Select Contact Type";
      },
      cellClass: 'table-cell',
      width: 234,

    },
    {
      field: 'application',
      headerName: "Application",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ["Select Application", "CBI"], 
      },
      valueFormatter: (params) => {
        return params.value === "Select Application" || !params.value ? "Select Application" : params.value;
      },
      cellClass: 'table-cell',
      width: 234,
    },
    {
      field: 'manufacturer_user_type_id',
      headerName: "Role",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.extractValues(),
      },
      valueFormatter: (params) => {
        return this.lookupValue(params.value);
      },
      cellClass: 'table-cell',
      width: 234,
    },
    {
      field: 'is_account_documents_visible',
      editable: true,
      headerName: "View all packages?",
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ["Select Package Permissions", false, true],
      },
      valueFormatter: (params) => {
        return params.value === false ? "No" : params.value === true ? "Yes" : "Select Package Permissions";
      },
      cellClass: 'table-cell',
      width: 234,
    },
  ];
  extractValues(): (number | string)[] { 
      const values = this.userService.getSubmissionEntityTypes().map(m => m.id);
      return ["Select User Role", ...values];
    }
  lookupValue(key: number | string): string {
    if (key === "Select User Role" || key == null) {
      return "Select User Role";
    }
    const id = this.userService.getSubmissionEntityTypes().find(m => m.id === key)?.text;
    return id ?? "Select User Role"; 
  }
}
